import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';
import { device } from '@theme/size';
import styled from 'styled-components';

export const StyleRegisterEmployee = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${px2rem(25)} ${px2rem(10)};

  h1 {
    font-family: "Messapia", sans-serif;
    font-size: ${px2rem(32)};
    margin-top: ${px2rem(15)};
    margin-bottom: ${px2rem(20)};
  }

  p {
    font-family: "Lexend", sans-serif;
    font-size: ${px2rem(16)};
    margin-bottom: ${px2rem(10)};
  }

  .form-switch {
    color: ${color.red};
    text-decoration: underline;
  }

  .form-paragraph {
    display: flex;
    flex-direction: column;
    margin-bottom: ${px2rem(30)};
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: ${px2rem(18)};
    width: 100%;
  }

  @media ${device.mobileM} {
    padding: ${px2rem(25)} ${px2rem(16)};
  }

  @media ${device.tablet} {
    padding: ${px2rem(30)} ${px2rem(16)} ${px2rem(40)} ${px2rem(16)};
  }

  @media ${device.laptop} {
    & > * {
      width: 100%;
      max-width: ${px2rem(960)};
    }

    .form-paragraph {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const InputText = styled.input`
  border: 1px solid #cbd6e2;
  outline: none;
  border-radius: ${px2rem(3)};
  width: 100%;
  padding: ${px2rem(0)} ${px2rem(15)};
  font-family: "Helvetica Neue", sans-serif, Helvetica, Arial;
  font-size: ${px2rem(16)};
  color: #33475b;
  height: ${px2rem(40)};
  min-height: ${px2rem(27)};
  background-color: #f5f8fa;

  &:focus {
    border: 1px solid #95c8f2;
    outline: 1px solid #95c8f2;
  }
`

export const Label = styled.label`
  font-family: sans-serif, helvetica,Arial ;
  font-size: ${px2rem(16)};
  line-height: ${px2rem(20)};
  color: #33475b;
  margin-bottom: ${px2rem(4)};

  span {
    color: ${color.red};
  }
`

export const Button = styled.input`
  background-color: #f24545;
  padding: ${px2rem(12)} ${px2rem(24)};
  border-radius: ${px2rem(3)};
  border-color: #f24545;
  border-style: solid;
  color: #fff;
  font-size: ${px2rem(14)};
  font-family: sans-serif, helvetica, Arial ;
  font-weight: 700;
  cursor: pointer;

`