import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StyleRegisterEmployee, InputText, Label, Button } from './index.styled';

const RegisterFormEmployee: React.FC = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [submitData, setSubmitData] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email, {
      NAME: name,
      PHONE: phone
    })
    .then(() => {
      setSubmitData(true);
    })
    .catch((error: Error) => {
      // Errors in here are client side
      // Mailchimp always returns a 200
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };
  
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.currentTarget.value);
  };
  
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return(
    <StyleRegisterEmployee>
      <div>
        <h1>Sumá tu perfil a Buba</h1>
        {
          !submitData ?
            <>
              <div className="form-paragraph">
                <p>Anotate para ser el primero en anotarte en Buba cuando esté listo para descargar</p>
                <AnchorLink to="/comercios/registro">
                  <p className="form-switch">¿Estás buscando empleados?</p>
                </AnchorLink>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-field">
                  <Label htmlFor="mce-NAME">Nombre <span>*</span></Label>
                  <InputText type="text" name="NAME" id="mce-NAME" onChange={handleNameChange} required />
                </div>
                <div className="form-field">
                  <Label htmlFor="mce-EMAIL">Email <span>*</span></Label>
                  <InputText type="text" name="EMAIL" id="mce-EMAIL" onChange={handleEmailChange} required />
                </div>
                <div className="form-field">
                  <Label htmlFor="mce-PHONE">Teléfono</Label>
                  <InputText type="text" name="PHONE" id="mce-PHONE" onChange={handlePhoneChange} />
                </div>
                <Button type="submit" value="Registrarme" name="suscribe" />
              </form>
            </>
            :
            <>
              <p>¡Muchas gracias! Te avisaremos cuando la aplicación esté disponible para descargar.</p>
            </>
        }
      </div>
    </StyleRegisterEmployee>
  );
};

export default RegisterFormEmployee;
